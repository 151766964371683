import React, {useState, Fragment} from 'react'

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import useWindowDimensions from './hooks/useWindowDimensions'

import { useMediaQuery } from 'react-responsive'

import './fonts/SubwayTickerGrid.ttf'


const percentChange = (start, end) => {
    if ((end === start) || (end - start === 0)) {
        return 0
    }
    try {
        return +(((end - start) / start) * 100).toFixed(2)
    }
    catch (err) {
        return Math.pow(10, 1000) // Max positive number
    }
}

export const Tckr2 = (props) => {

    const { height } = useWindowDimensions();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const { seriesdata } = props

    const { payload } = seriesdata;

    const initialSort = "date"

    let init = {}

    payload.forEach(s => {
        init[s.series_name.toUpperCase()] = s.series_name === 'tckr' ? true : false
    })

    const [visible, setVisible] = useState(init)

    const tckrData = payload.filter(p => p.series_name === 'tckr')[0].series_data

    const firstDate = tckrData[0].date
    const firstDateCorrected = new Date(firstDate)
    firstDateCorrected.setHours(firstDateCorrected.getHours() + 4)

    const lastDate = tckrData[tckrData.length - 1].date
    const lastDateCorrected = new Date(lastDate)
    lastDateCorrected.setHours(lastDateCorrected.getHours() + 4)

    const [min, setMin] = useState(firstDateCorrected.getTime())
    const [max, setMax] = useState(lastDateCorrected.getTime())

    const [dialogOpen, setDialogOpen] = useState(false)

    const handleOpenDialog = () => {
        setDialogOpen(true)
    }

    const handleCloseDialog = () => {
        setDialogOpen(false)
    }

    const tips = 
    <Fragment>
        <div style={{textAlign: 'center', background: 'none'}}>
        <Typography color="inherit">Help</Typography>
        <br/>
        {isTabletOrMobile &&
        <>
        <u>Clear</u>
        <br/>
        Clear the info popup by tapping the <FingerprintIcon fontSize='small' sx={{mt: 1.5}} /> button on the bottom left of the screen. 
        <br/><br/>
        </>
        }
        <u>Tips</u>
        <br/>
        Get helpful tips for how to navigate this page by clicking the ? button on the bottom right of the screen.
        <br/><br/>
        <u>Graphs</u>
        <br/>
        Toggle overlays for different assets by clicking the SYMBOL names in the legend at the bottom of the screen.
        <br/><br/>
        <u>Window</u>
        <br/>
        Adjust the viewing window by using the buttons in the upper left and right corners of the screen.
        <br/><br/>
        <u>Note</u>
        <br/>
        This graph updates every business day at 5:00PM EST.
        <br/><br/>
        </div>
    </Fragment>

    
    const payloadCalculated = payload ? payload.map((series, index) => {

        const name = series.series_name.toUpperCase()

        const color = series.series_color

        let days_elapsed = -1
        let portfolio_high = 0
        let current_draw_down = 0
        let max_draw_down = 0
        let percent_gain = 0

        const _data = series.series_data
            .sort((a, b) => -b[initialSort]?.localeCompare(a[initialSort]))
        
        let startingValue
    
        const data = _data.map((d, i) => {

            const _date = new Date(d.date)
            _date.setHours(_date.getHours() + 4)

            const _time = _date.getTime()

            const date = d.date
            const current_portfolio = d.value

            if (_time >= min && _time <= max) {

                days_elapsed += 1

                if (days_elapsed === 0) {
                    startingValue = d.value
                }
                
                if (current_portfolio >= portfolio_high) {
                    portfolio_high = current_portfolio
                }

                percent_gain = percentChange(startingValue, d.value)
                current_draw_down = percentChange(portfolio_high, current_portfolio)

                if (current_draw_down < max_draw_down) {
                    max_draw_down = current_draw_down
                }
                
            }

            const obj = {
                date: date,
                value: current_portfolio,
                percent_gain: percent_gain,
                current_draw_down: current_draw_down,
                max_draw_down: max_draw_down,
                days_elapsed: days_elapsed,
            } 

            return (obj)
            
        })

        return (
            {
                series_name: name,
                series_data: data,
                series_color: color,
            }
        )
    })
    :
    []
    
    const allSeries =payload ? payloadCalculated.map((series, index) => {

        const name = series.series_name.toUpperCase()

        const color = series.series_color

        const data = series.series_data
        .sort((a, b) => -b[initialSort]?.localeCompare(a[initialSort]))
        .map(d => {
            const date = Math.floor(new Date(d.date).getTime())
            const percent_gain = +d.percent_gain
            return ([date, percent_gain])
        })

        return (
            {
                name: name,
                data: data,
                color: color,
                visible: visible[name]
            }
        )
    })
    :
    []


    const config1 = {
        title: {
            text: 'TCKR'
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: true
        },
        series: allSeries,
        accessibility: {
            enabled: false
        },
        rangeSelector: {
            enabled: true,
            buttonTheme: { // styles for the buttons
                fill: 'none',
                stroke: 'none',
                'stroke-width': 0,
                r: 8,
                style: {
                    color: '#039',
                    fontWeight: 'bold'
                },
                states: {
                    hover: {
                    },
                    select: {
                        fill: '#A2A2A2',
                        style: {
                            color: 'white'
                        }
                    }
                // disabled: { ... }
                }
            },
            buttons: [{
                type: 'week',
                count: 1,
                text: '1w',
                events: {
                    click: function() {
                        // console.log('Clicked 1d button');
                        // setSelectedZoom(0)
                    }
                }
                }, {
                type: 'month',
                count: 1,
                text: '1m',
                events: {
                    click: function() {
                        // console.log('Clicked 7d button');
                        // setSelectedZoom(1)
                    }
                }
                }, {
                type: 'month',
                count: 3,
                text: '3m',
                events: {
                    click: function() {
                        // console.log('Clicked 1m button');
                        // setSelectedZoom(2)
                    }
                }
                }, {
                type: 'year',
                count: 1,
                text: '1y',
                events: {
                    click: function() {
                        // console.log('Clicked 3m button');
                        // setSelectedZoom(3)
                    }
                }
                },
                {
                type: 'all',
                text: 'All',
                events: {
                    click: function() {
                        // console.log('Clicked all button');
                        // setSelectedZoom(4)
                    }
                }
            }],
            // selected: selectedZoom,
            // verticalAlign: 'top',
        },
        chart: {
            height: `${height}px`,
            backgroundColor: '#000000',
            style: {
            fontFamily: 'Ticker',
            stroke: '#FFFFFF',
            fontSize: '11px'
            }
        },
        xAxis: {
            type: 'datetime',
            events: {
                afterSetExtremes: (event) => {
                    // console.log(event)
                    const newMin = new Date(event.min)
                    newMin.setHours(newMin.getHours() + 4)
                    const newMax = new Date(event.max)
                    newMax.setHours(newMax.getHours() + 4)
                    setMax(newMax.getTime())
                    setMin(newMin.getTime())
                }
            }
        },
        yAxis: [{
            offset: 20,

            labels: {
            formatter: function () {
                return `${(this.value)}%`
            }
            ,
            x: 0,
            style: {
                "color": "#000", "position": "absolute"

            },
            align: 'left'
            },
            title: {
                text: undefined
            },
            // plotLines: {
            //     color: '#000000'
            // }
        }],
        tooltip: {
            shared: true,
            useHtml: true,
            borderColor: '#FFFFFF',
            borderWidth: '1px',
            backgroundColor: '#000000',
            formatter: function (e) {

                let fullDatapoints = ''

                const reversed = payloadCalculated.toReversed()

                reversed.forEach((series, index) => {

                    const name = series.series_name.toUpperCase()

                    const {
                        date,
                        percent_gain,
                        current_draw_down,
                        max_draw_down,
                        days_elapsed
                    } = series.series_data[this.series.data.indexOf(this.point)]

                    const datapoints = `<b>${name}</b><br/>Date: ` + date +  '<br/>Percent Gain: ' + percent_gain +  '<br/>Current Drawdown: ' + current_draw_down + '<br/>Max Drawdown: ' + max_draw_down + '<br/>Days Elapsed: ' + days_elapsed + '<br/><br/>'

                    if (visible[name]) {
                        fullDatapoints += datapoints
                    }

                })

                // console.log(fullDatapoints)

                return fullDatapoints
            }
        },
        plotOptions: {
            series: {
                events: {
                    hide: function(e) {

                        const oldValue = visible[this.name] || false

                        const newVisible = {...visible, [this.name]: !oldValue }

                        setVisible(newVisible)

                    },
                    show: function(e) {

                        const oldValue = visible[this.name] || false

                        const newVisible = {...visible, [this.name]: !oldValue }

                        setVisible(newVisible)
                    }
                }
            }
        }

    }

    return( seriesdata &&
        <div
        style={{
            height: '100vh',
            backgroundColor: '#000000'
        }}
        >  
            <HighchartsReact
            highcharts={Highcharts}
            options={config1}
            /> 

            {isTabletOrMobile &&
            <IconButton size='small' sx={{position: 'absolute', left: '5px', bottom: '3px', color: '#FFFFFF'}}>
                <FingerprintIcon />
            </IconButton>
            }

            {/* <Tooltip title={tips}> */}
            <IconButton 
            onClick={handleOpenDialog}
            sx={{position: 'absolute', right: '10px', bottom: '3px', color: '#FFFFFF'}}
            >
                <HelpOutlineIcon />
            </IconButton>
            {/* </Tooltip> */}

            <Dialog 
            open={dialogOpen}
            onClose={handleCloseDialog}
            // sx={{background: 'none'}}
            //  fullWidth={true}
            // maxWidth="xl"
            >

                {tips}       
                

            <DialogActions>
                <Button onClick={handleCloseDialog}>Ok</Button>
            </DialogActions>
            </Dialog>   
            
        </div>
    )
}